import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Careers.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import AreaData from '../ServicesPage/AreaData'
import Checklist from '../../Images/checklist.png'
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import GarageImg1 from '../../Images/GarageImg1.jpg'
import GarageImg2 from '../../Images/GarageImg2.jpg'
import GarageImg3 from '../../Images/GarageImg3.jpg'
import GarageImg4 from '../../Images/GarageImg4.jpg'

export default function Careers() {
    const navigate = useNavigate()


    return (
        <div className="Careers">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Irvine Garage Door | Careers</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Irvine Garage Door | Careers" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Irvine Garage Door | Careers" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.irvinegaragedoor.org/careers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="The career path at Irvine Garage Door is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="The career path at Irvine Garage Door is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work!" data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Irvine Garage Door | Careers" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Join the Irvine Garage Door Team</h1>
                    <h2>See Job Postings in Irvine Area</h2>
                    <span>
                        <a href="tel:949-899-6983">CALL 949-899-6983<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                </div>

            </div>
            <div className="CareersBanner">
                <h1>Why Work at Irvine Garage Door</h1>
                <h2>The career path at Irvine Garage Door is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work! As a locally recognized business, Irvine Garage Door has a strong presence in the community and is supported by a team of dedicated professionals who keep the company growing. We encourage employees to develop their skills and advance their careers within the company or through relocation opportunities to expand their horizons.</h2>
                <span>
                    <div className="CareersBannerLeft">
                        <img src={GarageImg4} alt="" />
                    </div>
                    <div className="CareersBannerRight">
                        <h4>Mike W</h4>
                        <h5>Irvine Garage Door - Irvine, CA Branch</h5>
                        <h6>“I think Irvine Garage Door is a great choice for anyone who wants to succeed. You don’t sink or swim here; they help you along the way.”</h6>
                    </div>
                </span>
            </div>
            <div className="CareersBanner2">
                <h1>FIND A CAREER <br /> THAT'S RIGHT FOR YOU</h1>
                <div className="CareersBanner2List">
                    <div className="CareersBanner2ListTop">
                        <div className="CareersBanner2ListBox">
                            <img src={GarageImg1} alt="" />
                            <h2>Technicians</h2>
                        </div>
                        <div className="CareersBanner2ListBox">
                            <img src={GarageImg2} alt="" />
                            <h2>HR Managers</h2>
                        </div>
                        <div className="CareersBanner2ListBox">
                            <img src={GarageImg3} alt="" />
                            <h2>Office Managers</h2>
                        </div>
                    </div>

                </div>
                <h2>Email us at careers@irvinegaragedoor.org <br /> for more information</h2>
            </div>

            <NavigatorPath />

        </div>
    )
}

